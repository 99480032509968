import React, { useContext, useEffect, useState } from "react";
import "./RevisitDrawerContent.less";
import { RevisitNotes } from "./RevisitNotes";
import { Button, Col, Divider, Row, Spin } from "antd";
import { RevisitLineItems } from "./RevisitLineItems";
import { RevisitDetailsReason } from "./RevisitDetailsReason";
import RevisitCalendar from "./RevisitCalendar";
import { UserContext } from "../../../../../context/RootContext";
import { RevisitConstants } from "../RevisitConstants";
import getBffUrl from "../../../../../util/getBffUrl";
import moment from "moment";
import axios from "axios";
import { showNotification } from "../../../../../util/notification";
import * as constants from "../../../../../context/constants/deliverySlotsConstants";
import DeliveriesHook from "../../../../../hooks/deliveriesHook";
import OrderContext from "../../../../../context/OrderContext";
import useNewRelicMetrics from "../../../../../hooks/newRelicMetricsHook";
import {
  REVISIT_CANCEL_EVENT,
  REVISIT_NEXT_EVENT,
  REVISIT_SUBMIT_EVENT,
  OVERLAY_TYPE_REVISIT_COMMONS,
  REVISIT_SUBMIT_ERROR,
} from "../../../../../util/NewRelicConstants";
import { determineManuallyCode as determineRevisitTypeCode } from "../../../../../util/DetermineManuallyCode";
import {
  APPLIANCE_LINE,
  DEFAULT_REVISIT_LINE,
  PARTS_SERVICES_LINE,
  REVISIT_REPLACEMENT,
  REVISITS_WITH_DELIVERY_QUESTION,
} from "../../../../../util/Constants";

export default function RevisitDrawerContent({
  customerOrderNumber,
  workOrderNumber,
  purchaseOrderDetail,
  location,
  setDrawerOpen,
  customerDetail,
  setDrawerStatus,
  isRevisitSubmitted,
  setIsRevisitSubmitted,
  orderIndex,
}) {
  const { user } = useContext(UserContext);
  const { updateOrder } = useContext(OrderContext);

  const {
    deliverySlotsState,
    getDeliverySlots,
    slotsByDate,
    cleanDeliverySlots,
  } = DeliveriesHook(constants.REVISIT);

  const [revisitType, setRevisitType] = useState();
  const [notesContent, setNotesContent] = useState({
    text: "",
    date: "",
    time: "",
    user: {
      name: user.name,
      ldap: user.ldapId,
    },
  });

  const [itemsForRevisit, setItemsForRevisit] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [currentRevisitView, setCurrentRevisitView] = useState(
    RevisitConstants.RevisitsDetailStep
  );
  const [selectedDeliverySlot, setSelectedDeliverySlot] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    setDrawerStatus(currentRevisitView);
  }, [currentRevisitView]);

  const { logNewRelicMetricsEvent } = useNewRelicMetrics();

  function handleNext() {
    if (!isCurrentViewCalendar()) {
      getDeliverySlots(
        customerOrderNumber,
        workOrderNumber,
        location,
        customerDetail,
        itemsForRevisit,
        revisitType
      );
    }
    const date = new Date();
    const day = date.getDate();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    const currentDate = `${month}-${day}-${year}`;
    const currentTime = date.toLocaleTimeString();

    setNotesContent({ ...notesContent, date: currentDate, time: currentTime });
    setCurrentRevisitView(RevisitConstants.RevisitsCalendarStep);
    logNewRelicMetricsEvent(REVISIT_NEXT_EVENT, {
      order_number: customerOrderNumber,
      index: orderIndex,
      text: revisitType,
      overlay_type: OVERLAY_TYPE_REVISIT_COMMONS,
    });
  }

  function handleSubmit() {
    const payload = {};

    payload.createdBy = user.ldapId;
    payload.deliveryLocation = location;
    payload.customerOrderNumber = customerOrderNumber;
    payload.deliveryDate = `${moment(selectedDate).format("YYYY-MM-DD")}`;
    payload.deliverySkuCode = selectedDeliverySlot.deliverySkuCode;
    payload.deliverySkuCodeDesc = selectedDeliverySlot.deliverySkuCodeDesc;
    payload.deliveryPalletQuantity = selectedDeliverySlot.palletQuantity;
    payload.timeSlotType = selectedDeliverySlot.slotType;
    payload.deliveryWindowKey = selectedDeliverySlot.slotKey;
    payload.vehicleTypeCode = selectedDeliverySlot.vehicleTypeCode;
    payload.department = selectedDeliverySlot.department;
    payload.classNumber = selectedDeliverySlot.classNumber;
    payload.subclassNumber = selectedDeliverySlot.subclassNumber;
    payload.noteText = notesContent.text;
    payload.revisitLines = [];
    payload.revisitTypeCode = determineRevisitTypeCode(revisitType);
    itemsForRevisit.forEach((item) => {
      const revisitLines =
        revisitType === REVISIT_REPLACEMENT
          ? [createApplianceLine(item)]
          : [createDefaultLine(item)];
      payload.revisitLines = [...payload.revisitLines, ...revisitLines];
      item.partsAndServices.forEach((pasItem) => {
        payload.revisitLines = [
          ...payload.revisitLines,
          createPartAndServiceRevisitLine(item, pasItem),
        ];
      });
    });
    createRevisit(payload);
  }

  function createRevisit(payload) {
    setIsRevisitSubmitted(true);
    axios
      .post(`${getBffUrl()}/v1/delivery/createRevisit`, payload)
      .then(
        (response) =>
          new Promise((resolve) => setTimeout(() => resolve(response), 1500))
      )
      .then((response) => {
        if (response && response.status === 200) {
          logNewRelicMetricsEvent(REVISIT_SUBMIT_EVENT, {
            order_number: customerOrderNumber,
            index: orderIndex,
            text: revisitType,
            selected_date_slot_count: selectedDeliverySlot.availability
              ? parseInt(selectedDeliverySlot.availability)
              : 0,
            overlay_type: OVERLAY_TYPE_REVISIT_COMMONS,
          });
          showNotification(
            `${revisitType} successfully created for Order# ${customerOrderNumber}`,
            false
          );
          setDrawerOpen(false);
          updateOrder();
        } else {
          console.error("Error creating revisit");
          logNewRelicMetricsEvent(REVISIT_SUBMIT_ERROR, {
            order_number: customerOrderNumber,
            index: orderIndex,
            text: revisitType,
            selected_date_slot_count: selectedDeliverySlot.availability
              ? parseInt(selectedDeliverySlot.availability)
              : 0,
            overlay_type: OVERLAY_TYPE_REVISIT_COMMONS,
          });
          showNotification(
            `${revisitType} for Order# ${customerOrderNumber} was not created. Please, try again`,
            true
          );
        }
      })
      .catch((error) => {
        console.error(error);
        logNewRelicMetricsEvent(REVISIT_SUBMIT_ERROR, {
          order_number: customerOrderNumber,
          index: orderIndex,
          text: revisitType,
          selected_date_slot_count: selectedDeliverySlot.availability
            ? parseInt(selectedDeliverySlot.availability)
            : 0,
          overlay_type: OVERLAY_TYPE_REVISIT_COMMONS,
        });
        showNotification(
          `${revisitType} for Order# ${customerOrderNumber} was not created. Please, try again`,
          true
        );
      })
      .finally(() => setIsRevisitSubmitted(false));
  }

  function createPartAndServiceRevisitLine(item, partOrService) {
    const partOrServiceSkuCode = `${partOrService.modelNumber} ${
      partOrService.itemDesc ? partOrService.itemDesc : ""
    }`;
    const applianceSkuCode = `${item.modelNumber} ${
      item.itemDesc ? item.itemDesc : ""
    }`;

    return {
      originalLineNumber: partOrService.lineNumber,
      parentLineNumber: item.lineNumber,
      skuCode: partOrService.skuNumber,
      modelNumber: partOrService.modelNumber, // TODO: model number should not be null, backend returning validation error due to this one
      skuCodeDesc: `${partOrServiceSkuCode.substring(
        0,
        35
      )} - ${applianceSkuCode.substring(0, 35)}`,
      quantity: partOrService.quantity | 0,
      lineType: PARTS_SERVICES_LINE,
    };
  }

  function createDefaultLine(item) {
    const skuCode = `${item.modelNumber} ${item.itemDesc ? item.itemDesc : ""}`;
    return {
      originalLineNumber: item.lineNumber,
      parentLineNumber: item.lineNumber,
      skuCode: "863701",
      modelNumber: "Revisit",
      skuCodeDesc: `${revisitType} - ${skuCode.substring(0, 50)}`,
      quantity: 1,
      lineType: DEFAULT_REVISIT_LINE,
    };
  }

  function createApplianceLine(item) {
    return {
      originalLineNumber: item.lineNumber,
      parentLineNumber: item.lineNumber,
      skuCode: item.skuNumber,
      modelNumber: item.modelNumber,
      skuCodeDesc: item.itemDesc,
      quantity: item.quantity | 0,
      lineType: APPLIANCE_LINE,
    };
  }

  function validateItemForRevisit(item) {
    if (revisitType && REVISITS_WITH_DELIVERY_QUESTION.includes(revisitType)) {
      return (
        // uncomment it for 'Does the appliance need to be delivered?' section
        // typeof item.needToBeDelivered === "boolean" &&
        notesContent.text.length > 0
      );
    } else {
      return notesContent.text.length > 0;
    }
  }

  function isCurrentViewCalendar() {
    return currentRevisitView === RevisitConstants.RevisitsCalendarStep;
  }

  function handlePrevious() {
    cleanDeliverySlots();
    setCurrentRevisitView(RevisitConstants.RevisitsDetailStep);
    setSelectedDeliverySlot(null);
    setSelectedDate(null);
  }

  useEffect(() => {
    if (itemsForRevisit.length > 0) {
      if (itemsForRevisit.every(validateItemForRevisit)) {
        setIsButtonDisabled(false);
      } else {
        setIsButtonDisabled(true);
      }
    } else {
      setIsButtonDisabled(true);
    }
    if (isCurrentViewCalendar()) {
      if (selectedDate !== null && selectedDeliverySlot !== null) {
        setIsButtonDisabled(false);
      } else {
        setIsButtonDisabled(true);
      }
    }
  }, [
    revisitType,
    notesContent,
    itemsForRevisit,
    selectedDeliverySlot,
    selectedDate,
    currentRevisitView,
  ]);

  useEffect(() => {
    if (revisitType) {
      setItemsForRevisit([]);
    }
  }, [revisitType]);

  return (
    <div className="revisit-drawer-container">
      <Spin
        spinning={isRevisitSubmitted}
        data-testid="revisit-spinner"
        size="large"
        fullscreen={true}
      >
        <div className="revisit-drawer-content">
          <div hidden={isCurrentViewCalendar()}>
            <RevisitDetailsReason setRevisitType={setRevisitType} />
            <Divider />
            {revisitType && (
              <div className="revisit-main-content revisit-main-content-direction">
                <div className="revisit-item-selection">
                  <h3>Select Item for Revisit</h3>
                  <RevisitLineItems
                    setItemsForRevisit={setItemsForRevisit}
                    purchaseOrderDetail={purchaseOrderDetail}
                    itemsForRevisit={itemsForRevisit}
                    revisitType={revisitType}
                  />
                </div>
                <div className="revisit-notes">
                  <RevisitNotes
                    setNotesContent={setNotesContent}
                    notesContent={notesContent}
                  />
                </div>
              </div>
            )}
          </div>
          {isCurrentViewCalendar() && (
            <RevisitCalendar
              revisitType={revisitType}
              customerDetail={customerDetail}
              purchaseOrderDetail={purchaseOrderDetail}
              itemsForRevisit={itemsForRevisit}
              notesContent={notesContent}
              setNotesContent={setNotesContent}
              deliverySlots={slotsByDate}
              selectedDeliverySlot={selectedDeliverySlot}
              setSelectedDeliverySlot={setSelectedDeliverySlot}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              setIsSubmitDisabled={setIsButtonDisabled}
              deliverySlotsState={deliverySlotsState}
              reloadDeliverySlots={() =>
                getDeliverySlots(
                  customerOrderNumber,
                  workOrderNumber,
                  location,
                  customerDetail,
                  itemsForRevisit,
                  revisitType
                )
              }
            />
          )}
        </div>
      </Spin>
      <div className="revisit-footer">
        <Row className="revisit-actions-wrapper">
          <Col>
            <div className="revisit-actions">
              {isCurrentViewCalendar() && (
                <Button type="primary" onClick={handlePrevious}>
                  Previous
                </Button>
              )}
              <Button
                className="default-btn"
                onClick={() => {
                  setDrawerOpen(false);
                  logNewRelicMetricsEvent(REVISIT_CANCEL_EVENT, {
                    order_number: customerOrderNumber,
                    index: orderIndex,
                    text: revisitType,
                    overlay_type: OVERLAY_TYPE_REVISIT_COMMONS,
                  });
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  isCurrentViewCalendar() ? handleSubmit() : handleNext();
                }}
                type="primary"
                disabled={isButtonDisabled}
              >
                {isCurrentViewCalendar() ? "Submit" : "Next"}
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
