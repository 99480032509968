import * as constants from "./Constants";

export const userRole = (user) => {
  if (user && user.roles && user.roles.length) {
    const emtRoles = [];
    user.roles.forEach((role) => {
      if (role.substring(0, 3) === "EMT" && /\d{4}/g.exec(role.substring(4))) {
        emtRoles.push(role);
      }
    });
    if (user.roles.includes(constants.ROLE_EMT_CORP)) {
      return constants.ROLE_EMT_CORP;
    }
    if (user.isFDCUser) {
      return "EMT_FDC";
    }
    if (emtRoles.length) {
      return "EMT_N";
    }
    if (user.roles.includes(constants.ROLE_DMP_CALL_CENTER)) {
      return constants.ROLE_DMP_CALL_CENTER;
    }
    if (user.roles.includes(constants.ROLE_ADMS_MDO_USERS)) {
      return constants.ROLE_ADMS_MDO_USERS;
    }
    if (user.roles.includes(constants.ROLE_ADMS_LOGISTICS_MANAGERS)) {
      return constants.ROLE_ADMS_LOGISTICS_MANAGERS;
    }
    if (user.roles.includes(constants.ROLE_ADMS_LOGISTICS_USERS)) {
      return constants.ROLE_ADMS_LOGISTICS_USERS;
    }
    if (user.roles.includes(constants.ROLE_ADMS_DELIVERY_AGENTS)) {
      return constants.ROLE_ADMS_DELIVERY_AGENTS;
    }
    if (user.roles.includes(constants.ROLE_ADMS_VENDORS)) {
      return constants.ROLE_ADMS_VENDORS;
    }
  }
  return "Default";
};
