import Axios from "axios";
import {
  MAIL_REQUEST,
  MAIL_SUCCESS,
  MAIL_FAIL,
  DELETE_ALL_MAIL,
} from "../constants/mailConstants";
import getBffUrl from "../../util/getBffUrl";
import { DATE_FORMAT } from "../../util/Constants";

export const getMail = (dispatch) => async (ldap) => {
  dispatch({
    type: MAIL_REQUEST,
  });
  try {
    const { data } = await Axios.get(
      `${getBffUrl()}/mail/v1/mailHeaderDetails`,
      {
        params: { ldap },
      }
    );
    dispatch({
      type: MAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: MAIL_FAIL, payload: message });
  }
};

export const deleteMail = (dispatch) => () => {
  dispatch({
    type: DELETE_ALL_MAIL,
  });
};

export const getHistoryMail = (dispatch) => async (ldap, subject, date) => {
  dispatch({
    type: MAIL_REQUEST,
  });
  try {
    const params = { ldap };
    if (
      subject !== undefined &&
      subject !== null &&
      subject.trim().length !== 0
    ) {
      params.subject = subject.trim();
    }
    if (date !== undefined && date !== null) {
      params.date = date.format(DATE_FORMAT);
    }
    const { data } = await Axios.get(
      `${getBffUrl()}/mail/v1/mailHistoryHeaderDetails`,
      {
        params,
      }
    );
    dispatch({
      type: MAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: MAIL_FAIL, payload: message });
  }
};
