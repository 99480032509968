import React from "react";
import { Input } from "antd";
import "./RevisitNotes.less";
export function RevisitNotes({ setNotesContent, notesContent }) {
  const { TextArea } = Input;

  return (
    <div className="revisit-notes-container">
      <h3>Add Notes for this Revisit (required)</h3>
      <TextArea
        className="revisit-notes-textarea"
        rows={7}
        onChange={(e) => {
          setNotesContent({ ...notesContent, text: e.target.value });
        }}
      />
    </div>
  );
}
