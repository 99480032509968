import React, { useContext, useState } from "react";
import { Button, Modal } from "antd";
import permit from "../../util/Permissions";
import { UserContext } from "../../context/RootContext";
import useNewRelicMetrics from "../../hooks/newRelicMetricsHook";
import { TRUE_OVERAGE } from "../../util/NewRelicConstants";
import "./TrueOverageButton.less";

const TrueOverageButton = () => {
  const { user } = useContext(UserContext);
  const { logNewRelicMetricsEvent } = useNewRelicMetrics();
  const [isModalOpen, setIsModalOpen] = useState(false);

  // TODO: [EMT-XXX] Replace environment variable with config server feature flag
  // This will be updated when the config server implementation is ready
  const isFeatureEnabled = process.env.REACT_APP_ENABLE_TRUE_OVERAGE === "Y";

  if (!isFeatureEnabled) {
    return null;
  }

  return (
    <div className="true-overage-container">
      {permit("trueOverage", user) && (
        <>
          <Button
            type="primary"
            onClick={() => {
              logNewRelicMetricsEvent(TRUE_OVERAGE.BUTTON_CLICK);
              setIsModalOpen(true);
            }}
          >
            True Overage
          </Button>

          {/* TODO: Create TrueOverageModal component and replace this dummy modal */}
          <Modal
            id="true-overage-modal"
            title="True Overage Return Submission"
            open={isModalOpen}
            onCancel={() => setIsModalOpen(false)}
            footer={null}
          >
            <p>True Overage form will be implemented here</p>
          </Modal>
        </>
      )}
    </div>
  );
};

export default TrueOverageButton;
