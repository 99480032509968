import React from "react";
import "./RevisitToolTip.less";
import { Tooltip } from "antd";

export const RevisitSectionToolTip = ({ content }) => {
  const alertMessage = (
    <Tooltip>
      <div className="container">
        <p className="title">Part & Services</p>
        <p className="bottom-line"></p>
        <p className="text-message">
          Part & Services are
          <br />
          required to be carried
          <br />
          over from the original
          <br />
          order.
        </p>
      </div>
    </Tooltip>
  );

  return (
    <div>
      <div id="create-revisit-dropDown">
        <Tooltip placement="bottom" overlay={alertMessage} trigger={["hover"]}>
          {content()}
        </Tooltip>
      </div>
    </div>
  );
};
