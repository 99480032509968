import { useState, useEffect } from "react";
import { Checkbox, Row, Col } from "antd";
import "./RevisitPartAndService.less";
import { RevisitSectionToolTip } from "../../../../Actions/RevisitToolTip/RevisitSectionToolTip";

export function RevisitPartAndService({
  partAndServiceItem,
  itemsForRevisit,
  setItemsForRevisit,
  lineItem,
  setNeedToBeDelivered,
  id,
  partAndServiceId,
  checkedLineItem,
  setCheckedLineItem,
  revisitType,
  isReplacementRevisit,
}) {
  const [checkedPartAndService, setCheckedPartAndService] = useState(false);

  const fullPartAndServiceId = `${id}-${partAndServiceId}`;

  function checkExistingItem(itemsForRevisit, id) {
    return itemsForRevisit.find((item) => item.id === id);
  }

  const checkExistingPartService = (itemForRevisit) => {
    return itemForRevisit.partsAndServices.find(
      (item) => item.id === fullPartAndServiceId
    );
  };

  useEffect(() => {
    if (isReplacementRevisit) {
      setCheckedPartAndService(checkedLineItem);
    } else {
      if (checkedLineItem === false) {
        setCheckedPartAndService(false);
      }
    }
  }, [checkedLineItem]);

  useEffect(() => {
    if (revisitType) {
      setCheckedPartAndService(false);
    }
  }, [revisitType]);

  function buildNewItemForRevisit(removeOrAdd, currentItemForRevisit) {
    const partsAndServicesIdx =
      currentItemForRevisit.partsAndServices.findIndex((partAndService) => {
        return partAndService.id === fullPartAndServiceId;
      });

    const itemForRevisitIdx = itemsForRevisit.findIndex((itemForRevisit) => {
      return itemForRevisit.id === id;
    });

    if (removeOrAdd === "remove") {
      currentItemForRevisit.partsAndServices.splice(partsAndServicesIdx, 1);
      itemsForRevisit.splice(itemForRevisitIdx, 1);
      if (
        currentItemForRevisit.partsAndServices.length === 0 &&
        checkedLineItem === false
      ) {
        setNeedToBeDelivered(null);
        setItemsForRevisit([...itemsForRevisit]);
      } else {
        setItemsForRevisit([...itemsForRevisit, currentItemForRevisit]);
      }
    } else {
      itemsForRevisit.splice(itemForRevisitIdx, 1);
      currentItemForRevisit.partsAndServices.push({
        id: fullPartAndServiceId,
        skuNumber: partAndServiceItem.skuNumber,
        itemDesc: partAndServiceItem.itemDesc,
        lineNumber: partAndServiceItem.lineNumber,
        quantity: partAndServiceItem.quantity,
        modelNumber: partAndServiceItem.modelNumber,
        lineType: partAndServiceItem.lineType,
      });

      setItemsForRevisit([...itemsForRevisit, currentItemForRevisit]);
    }
  }

  function updatedPartsAndServices(existingItem) {
    const index = itemsForRevisit.findIndex(
      (item) => item.id === existingItem.id
    );
    const currentItemForRevisit = itemsForRevisit[index];
    if (checkExistingPartService(currentItemForRevisit)) {
      buildNewItemForRevisit("remove", currentItemForRevisit);
    } else {
      buildNewItemForRevisit("add", currentItemForRevisit);
    }
  }

  function handleChange() {
    setCheckedPartAndService(!checkedPartAndService);
    const existingItem = checkExistingItem(itemsForRevisit, id);
    if (existingItem) {
      updatedPartsAndServices(existingItem);
    } else {
      setCheckedLineItem(true);
      setItemsForRevisit([
        ...itemsForRevisit,
        {
          id: id,
          skuNumber: lineItem.skuNumber,
          lineNumber: lineItem.lineNumber,
          quantity: lineItem.quantity,
          itemDesc: lineItem.itemDesc,
          modelNumber: lineItem.modelNumber,
          partsAndServices: [
            {
              id: fullPartAndServiceId,
              skuNumber: partAndServiceItem.skuNumber,
              lineNumber: partAndServiceItem.lineNumber,
              quantity: partAndServiceItem.quantity,
              itemDesc: partAndServiceItem.itemDesc,
              modelNumber: partAndServiceItem.modelNumber,
              lineType: partAndServiceItem.lineType,
            },
          ],
        },
      ]);
    }
  }

  function isPartsAndServiceCancelled(partsAndServiceQuantity) {
    return (
      partsAndServiceQuantity &&
      !isNaN(partsAndServiceQuantity) &&
      Math.floor(partsAndServiceQuantity) === 0
    );
  }

  function renderPartAndService() {
    return (
      <div
        className={`part-and-service-container ${
          isReplacementRevisit ? "disabled-section" : null
        }`}
      >
        <div className="part-and-service-wrapper">
          <div className="checkbox-wrapper">
            <Checkbox
              data-testid="partAndService"
              id="partAndService"
              checked={checkedPartAndService}
              onChange={handleChange}
              disabled={
                isReplacementRevisit ||
                isPartsAndServiceCancelled(partAndServiceItem.quantity)
              }
            />
            <Row className="part-and-service-row">
              <Col>
                <div
                  className={`mcode-wrapper ${
                    isPartsAndServiceCancelled(partAndServiceItem.quantity)
                      ? "strike-cancelled-content"
                      : ""
                  }`}
                >
                  <span>{partAndServiceItem.modelNumber}</span>
                </div>
              </Col>
              <Col span={12}>
                <div
                  className={`part-and-service-item-desc ${
                    isPartsAndServiceCancelled(partAndServiceItem.quantity)
                      ? "strike-cancelled-content"
                      : ""
                  }`}
                >
                  <span>
                    {partAndServiceItem.itemDesc
                      ? partAndServiceItem.itemDesc
                      : ""}
                  </span>
                </div>
              </Col>
              <Col>
                <div
                  className={`part-and-service-quantity ${
                    isPartsAndServiceCancelled(partAndServiceItem.quantity)
                      ? "strike-cancelled-content"
                      : ""
                  }`}
                >
                  <span>Qty: {parseInt(partAndServiceItem.quantity)}</span>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }

  return isReplacementRevisit ? (
    <RevisitSectionToolTip content={renderPartAndService} />
  ) : (
    renderPartAndService()
  );
}
