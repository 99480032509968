import { Checkbox, Row, Col } from "antd";
import { useEffect, useState } from "react";
import { RevisitPartAndService } from "./RevisitPartAndService";
import { RevisitDeliveryState } from "./RevisitDeliveryState";
import "./RevisitLineItems.less";
import {
  PROTECTION_PLAN_LINE,
  REVISIT_REPLACEMENT,
} from "../../../../../util/Constants";
export const RevisitLineItem = ({
  itemsForRevisit,
  setItemsForRevisit,
  vendorName,
  lineItem,
  currentItemsForRevisit,
  id,
  msNumber,
  revisitType,
}) => {
  const {
    itemDesc,
    lineNumber,
    modelNumber,
    quantity,
    skuNumber,
    serialNumber,
    partsAndServices,
  } = lineItem;
  const isReplacementRevisit = revisitType === REVISIT_REPLACEMENT;
  const [checkedLineItem, setCheckedLineItem] = useState(false);
  const [needToBeDelivered, setNeedToBeDelivered] = useState(null);

  function findItemById(itemForRevisit, id) {
    return itemForRevisit.find((itemForRevisit) => itemForRevisit.id === id);
  }

  function isDuplicatedItem(array, id) {
    return array.some((item) => item.id === id);
  }

  function removeItemById(array, id) {
    return array.filter((item) => item.id !== id);
  }

  const filteredPartsAndServices =
    (partsAndServices &&
      partsAndServices.filter(
        (partsAndService) => partsAndService.lineType !== PROTECTION_PLAN_LINE
      )) ||
    [];

  useEffect(() => {
    const newItemForRevisit = findItemById(itemsForRevisit, id);
    if (!newItemForRevisit) {
      return;
    }

    newItemForRevisit["needToBeDelivered"] =
      typeof needToBeDelivered === "boolean" ? needToBeDelivered : null;
    let newItemsForRevisit = removeItemById(itemsForRevisit, id);
    newItemsForRevisit = [...newItemsForRevisit, newItemForRevisit];
    setItemsForRevisit(newItemsForRevisit);
  }, [needToBeDelivered]);

  useEffect(() => {
    if (revisitType) {
      setNeedToBeDelivered(null);
      setCheckedLineItem(false);
    }
  }, [revisitType]);

  function handleChange() {
    setCheckedLineItem(!checkedLineItem);
    if (isDuplicatedItem(itemsForRevisit, id)) {
      const newItemsForRevisit = removeItemById(itemsForRevisit, id);
      setNeedToBeDelivered(null);
      setItemsForRevisit(newItemsForRevisit);
    } else {
      setItemsForRevisit([
        ...itemsForRevisit,
        {
          id,
          skuNumber,
          lineNumber,
          vendorName,
          quantity,
          itemDesc,
          modelNumber,
          partsAndServices: isReplacementRevisit
            ? filteredPartsAndServices
            : [],
        },
      ]);
    }
  }

  function revisitDeliveryStateRow() {
    return (
      <Row
        style={{
          marginTop: "12px",
          fontWeight: "bold",
          minHeight: "32px",
        }}
      >
        <Col span={20}>
          <RevisitDeliveryState
            needToBeDelivered={needToBeDelivered}
            id={id}
            skuNumber={skuNumber}
            itemsForRevisit={itemsForRevisit}
            setNeedToBeDelivered={setNeedToBeDelivered}
            revisitType={revisitType}
          />
        </Col>
      </Row>
    );
  }

  return (
    <div>
      <div className="list-item-container">
        <div className="list-item-wrapper">
          <div className="checkbox-wrapper">
            <Checkbox
              value="appliance"
              id="appliance"
              checked={checkedLineItem}
              onChange={handleChange}
            />
            <div className="items-wrapper">
              <div className="item-desc">{itemDesc ? itemDesc : ""}</div>
              <div>
                <Row>
                  <Col span={6}>
                    <span>MSN #: {msNumber ? msNumber : "N/A"}</span>
                  </Col>
                  <Col span={6}>
                    <span>Qty: {parseInt(quantity)}</span>
                  </Col>
                </Row>
              </div>
              <Row>
                <Col span={6}>
                  <span>SKU # {skuNumber}</span>
                </Col>
                <Col span={8}>
                  <span>Model # {modelNumber ? modelNumber : "N/A"}</span>
                </Col>
                <Col span={6}>
                  {serialNumber ? <span>S/N: {serialNumber}</span> : null}
                </Col>
              </Row>
              {/* uncomment it to show 'Does the appliance need to be delivered?' section */}
              {/* {revisitDeliveryStateRow()} */}
            </div>
          </div>
        </div>
        <div className="list-item-service-container">
          {filteredPartsAndServices.length > 0 &&
            filteredPartsAndServices.map(
              (partAndServiceItem, partAndServiceIdx) => (
                <RevisitPartAndService
                  setCheckedLineItem={setCheckedLineItem}
                  partAndServiceId={partAndServiceIdx}
                  id={id}
                  key={partAndServiceIdx}
                  setNeedToBeDelivered={setNeedToBeDelivered}
                  lineItem={lineItem}
                  itemsForRevisit={itemsForRevisit}
                  setItemsForRevisit={setItemsForRevisit}
                  partAndServiceItem={partAndServiceItem}
                  checkedLineItem={checkedLineItem}
                  currentItemsForRevisit={currentItemsForRevisit}
                  revisitType={revisitType}
                  isReplacementRevisit={isReplacementRevisit}
                />
              )
            )}
        </div>
      </div>
    </div>
  );
};

export function RevisitLineItems({
  setItemsForRevisit,
  purchaseOrderDetail,
  itemsForRevisit,
  revisitType,
}) {
  return (
    <div className="list-of-items-container">
      {purchaseOrderDetail.map((detail, detailIdx) => {
        return detail.lineItems.map((lineItem, idx) => (
          <div
            key={`${detailIdx}-${idx}`}
            className="list-of-items-subcontainer"
          >
            <div className="list-of-items-wrapper">
              <RevisitLineItem
                msNumber={detail.msNumber}
                id={`${detailIdx}-${idx}`}
                lineItem={lineItem}
                vendorName={detail.vendorName}
                setItemsForRevisit={setItemsForRevisit}
                itemsForRevisit={itemsForRevisit}
                revisitType={revisitType}
              />
            </div>
          </div>
        ));
      })}
    </div>
  );
}
