import React from "react";
import { Divider } from "antd";
import "./RevisitSummary.less";

function renderDeliveryStatusSection(itemForRevisit) {
  return (
    <p className="summary-paragraph">
      {itemForRevisit.needToBeDelivered ? (
        <span className="delivery-status">Item needs to be delivered</span>
      ) : (
        <span className="delivery-status">Item is in the home</span>
      )}
    </p>
  );
}

export function RevisitSummary({ itemForRevisit, vendorName }) {
  return (
    <div className="revisit-summary">
      <h4 className="summary-title">Affected item:</h4>
      <p className="summary-paragraph">
        <span className="vendor-name">{vendorName}:</span>
        <span className="model-number">
          {itemForRevisit.modelNumber ? itemForRevisit.modelNumber : "N/A"}
        </span>
      </p>
      {/* // replace section for 'Does the appliance need to be delivered?' */}
      {/* {renderDeliveryStatusSection(itemForRevisit)} */}
      <p className="parts-and-services-title">Parts and Services:</p>
      {itemForRevisit.partsAndServices.length > 0 ? (
        itemForRevisit.partsAndServices.map((partAndService, idx) => (
          <p key={idx} className="part-and-service-item">
            {partAndService.itemDesc || partAndService.modelNumber || ""}
          </p>
        ))
      ) : (
        <span className="part-and-service-item">N/A</span>
      )}
      <Divider />
    </div>
  );
}
