import React, { useEffect, useState } from "react";
import { Drawer, Button, Steps } from "antd";
import RevisitDrawerContent from "./RevisitDrawerContent/RevisitDrawerContent";
import "./RevisitDrawer.less";
import { CloseOutlined } from "@ant-design/icons";
import { buildDrawerBorder } from "../../ResultTable/helpers/helpers";
import { RevisitConstants } from "./RevisitConstants";
export default function RevisitDrawer({
  customerOrderNumber,
  workOrderNumber,
  isDrawerOpen,
  setDrawerOpen,
  purchaseOrderDetail,
  location,
  customerDetail,
  orderCardColor,
  orderIndex,
}) {
  const drawerBorder = buildDrawerBorder(orderCardColor);
  const [drawerStatus, setDrawerStatus] = useState("details");
  const [drawerItems, setDrawerItems] = useState({
    current: 0,
    items: [
      {
        title: "In Progress",
        description: "Revisit Details",
      },
      {
        title: "Waiting",
        description: "Select Date for Revisit",
      },
      {
        icon: "null",
      },
    ],
  });
  const [isRevisitSubmitted, setIsRevisitSubmitted] = useState(false);

  useEffect(() => {
    switch (drawerStatus) {
      case RevisitConstants.RevisitsDetailStep:
        {
          setDrawerItems({
            current: 0,
            items: [
              {
                title: "In Progress",
                description: "Revisit Details",
              },
              {
                title: "Waiting",
                description: "Select Date for Revisit",
              },
              {
                icon: "null",
              },
            ],
          });
        }
        break;
      case RevisitConstants.RevisitsCalendarStep:
        {
          setDrawerItems({
            current: 1,
            items: [
              {
                title: "Finished",
                description: "Revisit Details",
              },
              {
                title: "In Progress",
                description: "Select Date for Revisit",
              },
              {
                icon: "null",
              },
            ],
          });
        }
        break;
    }
  }, [drawerStatus]);

  return (
    <Drawer
      drawerStyle={drawerBorder}
      height="80vh"
      title={
        <div className="drawer-header">
          <div className="header-title">
            <span className="drawer-title">Delivery Revisit</span>
          </div>
          <div className="steps-wrapper">
            <Steps
              className="drawer-steps-container"
              current={drawerItems.current}
              items={drawerItems.items}
            />
          </div>
          <div className="close-button-wrapper">
            <Button
              type="text"
              style={{ float: "right" }}
              icon={<CloseOutlined />}
              onClick={() => setDrawerOpen(false)}
              disabled={isRevisitSubmitted}
            />
          </div>
        </div>
      }
      placement="bottom"
      closable={false}
      onClose={() => {
        if (!isRevisitSubmitted) {
          setDrawerOpen(false);
        }
      }}
      open={isDrawerOpen}
    >
      {/* IMPORTANT TO HAVE THIS CONDITION TO CLEAR THE CONTENT ONCE DRAWER IS CLOSED */}
      {isDrawerOpen && (
        <RevisitDrawerContent
          customerOrderNumber={customerOrderNumber}
          workOrderNumber={workOrderNumber}
          customerDetail={customerDetail}
          purchaseOrderDetail={purchaseOrderDetail}
          location={location}
          setDrawerOpen={setDrawerOpen}
          setDrawerStatus={setDrawerStatus}
          isRevisitSubmitted={isRevisitSubmitted}
          setIsRevisitSubmitted={setIsRevisitSubmitted}
          orderIndex={orderIndex}
        />
      )}
    </Drawer>
  );
}
