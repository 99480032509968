const actionButtonAccess = {
  reschedule: {
    "Work Order Created": true,
    "Dispatch Rejected": true,
    "Submitted And Approved": true,
    "Ready For Load": true,
    "Delivery Attempted": true,
  },

  pod: {
    "Delivery Completed": true,
    "Delivery Attempted": true,
    "Delivery Confirmation Obtained": true,
  },

  sthPod: {
    COMPLETED: true,
    MSD_WO_ATT: true,
    MSD_W_ATT: true,
    COMP_W_EXP: true,
  },
};

export const checkActionAccessForOrderStatus = (actionInput) => {
  return (
    actionButtonAccess[actionInput.action][actionInput.statusForAction] !==
    undefined
  );
};

export const isDeepEqual = (obj1, obj2) => {
  // Check if the objects are the same reference
  if (obj1 === obj2) {
    return true;
  }

  // Check if the objects have the same keys
  const obj1Keys = Object.keys(obj1);
  const obj2Keys = Object.keys(obj2);
  if (obj1Keys.length !== obj2Keys.length) {
    return false;
  }

  // Check if the values of each key are deeply equal
  for (const key of obj1Keys) {
    const val1 = obj1[key];
    const val2 = obj2[key];

    if (typeof val1 === "object" && typeof val2 === "object") {
      if (!isDeepEqual(val1, val2)) {
        return false;
      }
    } else if (val1 !== val2) {
      return false;
    }
  }

  return true;
};

export const areAllValuesNull = (obj) => {
  for (const key in obj) {
    if (obj[key] !== null) {
      return false;
    }
  }
  return true;
};

export function hasFilterChanged(filters, trackedFilters) {
  for (const key in filters) {
    if (
      (filters[key] !== null && trackedFilters[key] === null) ||
      (filters[key] === null && trackedFilters[key] !== null)
    ) {
      return true;
    }
    if (!filters[key] !== null && trackedFilters[key] !== null) {
      if (
        filters[key] &&
        trackedFilters[key] &&
        filters[key].length !== trackedFilters[key].length
      ) {
        return true;
      }
    }
    if (filters[key] !== trackedFilters[key]) {
      return true;
    }
  }
  return false;
}

export function hasSortChanged(sorter, trackedSorter) {
  if (trackedSorter.columnKey !== sorter.columnKey) {
    return true;
  }
  if (trackedSorter.order !== sorter.order) {
    return true;
  }
  return false;
}

export const areAllFiltersNull = (filters) => {
  for (const key in filters) {
    if (filters[key] !== null) {
      return false;
    }
  }
  return true;
};

export function buildInitialFiltersOptions(data, existing = {}) {
  const filterNames = [
    "deliveryDate",
    "deliveryWindow",
    "workOrderStatus",
    "isAppliance",
    "deliveryLocation",
    "promiseDate",
    "workOrder",
    "orderType",
    "isAttended",
  ];
  const trackVals = {
    deliveryDate: existing.deliveryDate
      ? new Set(existing.deliveryDate.map(({ text }) => text))
      : new Set(),
    deliveryWindow: existing.deliveryWindow
      ? new Set(existing.deliveryWindow.map(({ text }) => text))
      : new Set(),
    workOrderStatus: existing.workOrderStatus
      ? new Set(existing.workOrderStatus.map(({ text }) => text))
      : new Set(),
    isAppliance: existing.isAppliance
      ? new Set(existing.isAppliance.map(({ text }) => text))
      : new Set(),
    deliveryLocation: existing.deliveryLocation
      ? new Set(existing.deliveryLocation.map(({ text }) => text))
      : new Set(),
    promiseDate: existing.promiseDate
      ? new Set(existing.promiseDate.map(({ text }) => text))
      : new Set(),
    workOrder: existing.workOrder
      ? new Set(existing.workOrder.map(({ text }) => text))
      : new Set(),
    orderType: existing.orderType
      ? new Set(existing.orderType.map(({ text }) => text))
      : new Set(),
    isAttended: existing.isAttended
      ? new Set(existing.isAttended.map(({ text }) => text))
      : new Set(),
  };

  data.forEach((dataObj) => {
    filterNames.forEach((filterName) => {
      if (!trackVals[filterName].has(dataObj[filterName])) {
        trackVals[filterName].add(dataObj[filterName]);
      }
    });
  });

  const result = Object.keys(trackVals).reduce((result, key) => {
    result[key] = Array.from(trackVals[key]).map((value) => ({
      text: value,
      value,
    }));
    return result;
  }, {});

  return result;
}

export function stringOptionsSorter(array) {
  const sortedArray = [...array];
  return sortedArray.sort((a, b) => {
    return a.text.localeCompare(b.text);
  });
}

export function dateOptionsSorter(array) {
  const sortedArray = [...array];
  return sortedArray.sort((a, b) => {
    if ((a.text || b.text) === "No Delivery Date") {
      return -1;
    }
    return new Date(a.text) - new Date(b.text);
  });
}

//WARNING: This function assumes that the argument (deliveryWindow) is in plain text and always
//in the format HH:MM AM/PM - HH:MM AM/PM (Ex: 07:00 AM - 07:00 PM)
//DO NOT use this function in different format
export function getStartTime(deliveryWindow) {
  const [startTime, endTime] = deliveryWindow.split(" - ");

  const date = new Date(`1970-01-01  ${startTime}`);
  const minutes = date.getHours() * 60 + date.getMinutes();
  return minutes;
}

export function dateDurationOptionsSorter(array) {
  const sortedArray = [...array];
  return sortedArray.sort((a, b) => {
    if ((a.text || b.text) === "Delivery Window Not Set") {
      return -1;
    }
    const startTimeA = getStartTime(a.text);
    const startTimeB = getStartTime(b.text);

    if (startTimeA < startTimeB) {
      return -1;
    } else if (startTimeB < startTimeA) {
      return 1;
    }
  });
}

export function buildDrawerBorder(borderColor) {
  let hexColor;

  switch (borderColor) {
    case "blue":
      hexColor = "#2081c9";
      break;
    case "green":
      hexColor = "#00ae4d";
      break;
    case "red":
      hexColor = "#ed1c24";
      break;
    case "gray":
      hexColor = "#939393";
      break;
    default:
      hexColor = "";
  }

  return {
    borderTop: `2px solid ${hexColor}`,
    borderLeft: `2px solid ${hexColor}`,
    borderBottom: `2px solid ${hexColor}`,
    borderRight: `2px solid ${hexColor}`,
  };
}
