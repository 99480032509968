import React, { useState, useEffect } from "react";
import { Button, Col, Divider } from "antd";
import { Calendar, Typography, Spin } from "antd";
import "./RevisitCalendar.less";
import { RevisitSummary } from "./RevisitSummary";
import RevisitNotesContent from "./RevisitNotesContent";
import moment from "moment";

export default function RevisitCalendar({
  purchaseOrderDetail,
  customerDetail,
  itemsForRevisit,
  revisitType,
  notesContent,
  deliverySlots,
  selectedDeliverySlot,
  setSelectedDeliverySlot,
  selectedDate,
  setSelectedDate,
  setIsSubmitDisabled,
  deliverySlotsState,
  reloadDeliverySlots,
}) {
  const [earlierDeliveryDate, setEarlierDeliveryDate] = useState(null);

  // deliverySlots = mockDeliverySlots;

  useEffect(() => {
    getEarlierDeliveryDate();
  }, [deliverySlots]);

  function getEarlierDeliveryDate() {
    if (deliverySlots && deliverySlots.length > 0) {
      setEarlierDeliveryDate(
        moment.utc(deliverySlots[0].date).format("YYYY-MM-DD")
      );
    } else {
      setEarlierDeliveryDate(null);
    }
  }

  function formatDate(date) {
    return date === null ? "NULL" : moment(date).format("YYYY-MM-DD");
  }

  function getDeliverySlot(date) {
    let slot = null;
    if (date !== null) {
      const passDate = moment.utc(date).format("YYYY-MM-DD");
      deliverySlots &&
        deliverySlots.length > 0 &&
        deliverySlots.map((deliverySlot) => {
          const deliveryDate = moment
            .utc(deliverySlot.date)
            .format("YYYY-MM-DD");
          if (passDate === deliveryDate) {
            slot = deliverySlot;
          }
        });
    }
    return slot;
  }

  function datesWithNoSlots(current) {
    const deliveryDates = deliverySlots.map((item) =>
      moment(item.date).format("YYYY-MM-DD")
    );
    return !deliveryDates.includes(moment(current).format("YYYY-MM-DD"));
  }

  return (
    <div className="revisit-calendar-section">
      <div className="revisit-info child">
        <h3 className="customer-detail">{`${customerDetail.firstName} ${customerDetail.lastName}'s revisit`}</h3>
        <p>{revisitType}</p>
        <div className="general-info">
          {itemsForRevisit.map((itemForRevisit, idx) => {
            return (
              <RevisitSummary
                vendorName={itemForRevisit.vendorName}
                key={idx}
                purchaseOrderDetail={purchaseOrderDetail}
                itemForRevisit={itemForRevisit}
                customerDetail={customerDetail}
                notesContent={notesContent}
              />
            );
          })}
          <RevisitNotesContent notesContent={notesContent} />
        </div>
      </div>
      <Col xs={1} align="center" className="vertical-dividers child">
        <Divider type="vertical" />
      </Col>
      <div className="calendar-container child">
        <div>
          <h3 className="calendar-heading">Select a Date</h3>
          <div className="delivery-info-container">
            <p className="delivery-info">{`Earliest delivery for zip code ${customerDetail.zipCode} is`}</p>
            <span className="spinner-container">
              {deliverySlotsState.loading ? (
                <Spin data-testid="slot-spinner" size="small" />
              ) : (
                <p className="delivery-info">
                  {formatDate(earlierDeliveryDate)}
                </p>
              )}
            </span>
          </div>
          {selectedDate && (
            <div className="delivery-slots">
              <div className="delivery-slots-content">
                <div className="slot-title">
                  <Typography.Title level={1} className="typography-title">
                    {`${
                      selectedDeliverySlot && selectedDeliverySlot.availability
                        ? selectedDeliverySlot.availability
                        : 0
                    }`}
                  </Typography.Title>
                </div>
                <div className="slot-details">
                  <p>
                    Delivery Slots remaining for the zip code{" "}
                    <span className="highlighted-details">
                      {customerDetail.zipCode}
                    </span>{" "}
                    on{" "}
                    <span className="highlighted-details">{`${formatDate(
                      selectedDate
                    )}`}</span>
                  </p>
                </div>
              </div>
            </div>
          )}
          {!deliverySlotsState.loading && !earlierDeliveryDate && (
            <div className="delivery-slots-error">
              <div className="slot-details-error">
                <p>The calendar failed to load.</p>
                <p>Click reload below to refresh the calendar.</p>
                <div className="slot-details-error-button">
                  <Button
                    width="40%"
                    type="primary"
                    onClick={reloadDeliverySlots}
                    size="small"
                  >
                    Reload Calendar
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="child calendar-comp calendar-spin-container">
        {deliverySlotsState.loading && (
          <div className="spinner-position">
            <Spin data-testid="calendar-spinner" size="large" />
          </div>
        )}
        <div
          className={`${
            selectedDate ? "calendar-wrapper" : "calendar-wrapper-default"
          }`}
        >
          {!deliverySlotsState.loading && earlierDeliveryDate ? (
            <Calendar
              fullscreen={false}
              value={
                selectedDate
                  ? moment.utc(selectedDate)
                  : moment.utc(earlierDeliveryDate)
              }
              disabledDate={datesWithNoSlots}
              onSelect={(date) => {
                setSelectedDate(date);
                setSelectedDeliverySlot(getDeliverySlot(date));
                setIsSubmitDisabled(false);
              }}
            />
          ) : (
            <Calendar fullscreen={false} disabledDate={() => true} />
          )}
        </div>
      </div>
    </div>
  );
}
