import React from "react";
import "./RevisitNotesContent.less";

export default function RevisitNotesContent({ notesContent }) {
  return (
    <div className="revisit-notes-content">
      <p className="notes-title">Notes:</p>
      <span className="notes-info">
        {notesContent.date} | {notesContent.time} | {notesContent.user.ldap}
      </span>
      <p className="notes-text">{notesContent.text}</p>
    </div>
  );
}
