import React, { useContext } from "react";
import { Radio } from "antd";
import OrderContext from "../../../../../context/OrderContext";

import "./RevisitDetailsReason.less";
import useRevisitHook from "../../../../../hooks/useRevisitHook";

export function RevisitDetailsReason({ setRevisitType }) {
  const { enabledComRevisitReasons } = useRevisitHook();
  const { revisitType } = useContext(OrderContext);

  if (revisitType) {
    setRevisitType(revisitType);
  }

  return (
    <div className="revisit-reasons-container">
      <span className="drawer-subtitle">Select Details for Revisit</span>
      <div className="radio-options-container">
        {revisitType ? (
          <Radio checked={true} className="drawer-subtitle" value={revisitType}>
            {revisitType}
          </Radio>
        ) : (
          <Radio.Group
            className="radio-options-wrapper"
            onChange={(e) => {
              setRevisitType(e.target.value);
            }}
          >
            {enabledComRevisitReasons.map((option) => (
              <div key={option.label}>
                <Radio
                  key={option.label}
                  className="drawer-subtitle"
                  value={option.label}
                >
                  {option.label}
                </Radio>
              </div>
            ))}
          </Radio.Group>
        )}
      </div>
    </div>
  );
}
