import { useMemo, useContext } from "react";
import * as constants from "../util/Constants";
import { UserContext } from "../context/RootContext";
import { userRole } from "../util/Roles";

export default function useRevisitHook() {
  const { user } = useContext(UserContext);
  const role = userRole(user);

  const config = useMemo(() => {
    const revisitEnabled = process.env.REACT_APP_REVISIT_ENABLED === "Y";
    const enabledLocations = process.env.REACT_APP_REVISIT_LOCATIONS
      ? process.env.REACT_APP_REVISIT_LOCATIONS.split(",")
      : [];
    const enabledRevisitTypes = process.env.REACT_APP_REVISIT_TYPES
      ? process.env.REACT_APP_REVISIT_TYPES.split(",")
      : [];

    return {
      revisitEnabled,
      enabledLocations,
      enabledRevisitTypes,
    };
  }, []);

  const isRevisitFeatureEnabled = () => config.revisitEnabled;

  const isLocationEnabled = (location) => {
    return config.enabledLocations.includes(location);
  };

  const isRevisitTypeEnabled = (revisitType) => {
    return config.enabledRevisitTypes.includes(revisitType);
  };

  const getEnabledComRevisitReasons = (role) => {
    const filteredRevisitReasonsByRole = createRevisitReasons(role);

    return config.enabledRevisitTypes
      .map((type) =>
        filteredRevisitReasonsByRole.find((reason) => reason.key === type)
      )
      .filter(Boolean)
      .map(({ key, label }) => ({ key, label }));
  };

  const shouldShowRevisitButtonOnDashboardCommon = (location) => {
    return isRevisitFeatureEnabled() && isLocationEnabled(location);
  };

  const shouldLoadRevisitDrawerInDashboard = (location, revisitType) => {
    return (
      shouldShowRevisitButtonOnDashboardCommon(location) &&
      isRevisitTypeEnabled(revisitType)
    );
  };

  const createRevisitReasons = (role) => {
    switch (role) {
      case constants.ROLE_EMT_CORP:
      case constants.ROLE_EMT_N:
      case constants.ROLE_ADMS_MDO_USERS:
        return [
          { key: constants.REVISIT_REPLACEMENT, label: "Replacement" },
          { key: constants.REVISIT_PICKUP, label: "Pick-up for Return" },
          {
            key: constants.REVISIT_DA_SERVICE_CALL,
            label: "Delivery Agent Service Call",
          },
          {
            key: constants.REVISIT_PLUMBER_ELECTRICIAN_SERVICE_CALL,
            label: "Plumber/Electrician Service Call",
          },
          { key: constants.REVISIT_OTHER, label: "Other" },
        ];
      case constants.ROLE_ADMS_DELIVERY_AGENTS:
        return [
          { key: constants.REVISIT_REPLACEMENT, label: "Replacement" },
          { key: constants.REVISIT_PICKUP, label: "Pick-up for Return" },
          {
            key: constants.REVISIT_DA_SERVICE_CALL,
            label: "Delivery Agent Service Call",
          },
          {
            key: constants.REVISIT_PLUMBER_ELECTRICIAN_SERVICE_CALL,
            label: "Plumber/Electrician Service Call",
          },
          { key: constants.REVISIT_OTHER, label: "Other" },
        ];
      case constants.ROLE_ADMS_VENDORS:
        return [{ key: constants.REVISIT_REPLACEMENT, label: "Replacement" }];
      case constants.ROLE_DMP_CALL_CENTER:
        return [
          { key: constants.REVISIT_PICKUP, label: "Pick-up for Return" },
          {
            key: constants.REVISIT_DA_SERVICE_CALL,
            label: "Delivery Agent Service Call",
          },
          { key: constants.REVISIT_OTHER, label: "Other" },
        ];
      default:
        return [];
    }
  };

  const revisitReasons = createRevisitReasons(role);
  const enabledComRevisitReasons = getEnabledComRevisitReasons(role);

  return {
    shouldShowRevisitButtonOnDashboardCommon,
    shouldLoadRevisitDrawerInDashboard,
    enabledComRevisitReasons,
    revisitReasons,
  };
}
