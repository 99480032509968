import React, { useState, useEffect, useContext } from "react";
import "./DeliveryDetails.less";
import CustomerInfo from "../CustomerInfo/CustomerInfo";
import Notes from "../Notes/Notes";
import Actions from "../Actions/Actions";
import DeliveryWindow from "../DeliveryWindow/DeliveryWindow";
import PropTypes from "prop-types";
import PurchaseOrders from "../ProductPOInfo/PurchaseOrders";
import WorkOrderDetail from "../WorkOrderDetail/WorkOrderDetail";
import OrderContext from "../../context/OrderContext";
import axios from "axios";
import * as constants from "../../util/Constants";
import ManualOrder from "../ManualOrder/ManualOrder";
import { Row, Col } from "antd";
import getBffUrl from "../../util/getBffUrl";
import moment from "moment";
import RevisitDrawer from "../DeliveriesCommon/OrderDetails/RevisitDrawer/RevisitDrawer";
import {
  updateCustomerDetail,
  updatePurchaseOrderDetail,
} from "../DeliveriesCommon/OrderDetails/RevisitDrawer/helper";
import useRevisitHook from "../../hooks/useRevisitHook";

const DeliveryDetails = (props) => {
  const { shouldLoadRevisitDrawerInDashboard } = useRevisitHook();
  const [orderDetails, setOrderDetails] = useState(props.orderDetails);

  const [openManualOrder, toggleManualOrder] = useState(false);

  const [isRevisitDrawerOpen, setRevisitDrawerOpen] = useState(false);
  const [isCustomerInfoUpdated, setCustomerInfoIsUpdated] = useState(false);
  const [isUpdateCustomerInfoLoading, setUpdateCustomerInfoIsLoading] =
    useState(false);
  const [isCustomerInfoOpen, openCustomerInfoModal] = useState(false);
  const [revisitType, setRevisitType] = useState(null);
  const { deliveries, showDeliveryDate, orderIndex } = props;
  const { orderEvents } = orderDetails.deliveryWindowDetail || [];
  const statusColor = getColorFromEventList();

  const { customerDetail, deliveryDetail } = orderDetails;
  const { poDetailList, woDetail, deliveryAgent } = deliveryDetail;
  const [updatedCustomerDetail, setUpdatedCustomerDetail] = useState(null);
  const [updatedPurchaseOrderDetails, setUpdatedPurchaseOrderDetails] =
    useState(null);

  useEffect(() => {
    if (deliveryDetail) {
      setUpdatedCustomerDetail(updateCustomerDetail(customerDetail));
      //If the deliveryDetail has a poDetailList (case of Appliance dashboard), then we need to update the purchase order details and build new mappings
      if (deliveryDetail.poDetailList) {
        setUpdatedPurchaseOrderDetails(
          updatePurchaseOrderDetail(deliveryDetail, woDetail)
        );
      }
    }
  }, [isRevisitDrawerOpen]);

  const updateOrder = (delay) => {
    setTimeout(
      () => {
        axios
          .get(`${getBffUrl()}/dashboard/v1/driversDeliveries`, {
            params: { DeliveryID: orderDetails.referenceId },
          })
          .then((response) => {
            if (response && response.status === 200) {
              setDelivery(response.data);
            } else {
              console.log("Error accessing the dashboard data");
            }
          })
          .catch(() => {
            console.log(
              `Error calling Order module data with Delivery ID: ${orderDetails.referenceId}`
            );
          });
      },
      delay ? parseInt(process.env.REACT_APP_DELAY_TME_AFTER_MICRO_UI, 10) : 0
    );
  };

  const setDelivery = (deliveries) => {
    if (deliveries && deliveries[0] && deliveries[0].unassignedOrders) {
      setOrderDetails(deliveries[0].unassignedOrders[0]);
    }
  };

  function getLocation() {
    return (
      orderDetails.deliveryDetail &&
      orderDetails.deliveryDetail.deliveryAgent &&
      orderDetails.deliveryDetail.deliveryAgent.tmsId
    );
  }

  const location = getLocation();

  function statusString(delivery) {
    const { customerDetail, deliveryWindowDetail } = delivery;
    const stopNumber = delivery.truckStopNumber;
    let deliveryDate = new Date(customerDetail.scheduledDeliveryDate);
    let isComplete = Boolean(false);

    for (let i = deliveryWindowDetail.orderEvents.length - 1; i >= 0; i--) {
      const eventCode = deliveryWindowDetail.orderEvents[i].orderEventCode;
      if (eventCode === constants.COMPLETE) {
        deliveryDate = deliveryWindowDetail.orderEvents[
          i
        ].orderEventCrtTimeStamp.slice(0, 8);
        isComplete = true;
        break;
      }
    }
    if (isComplete) {
      return deliveries.truckNumber != null && stopNumber != null
        ? `Delivered on ${deliveryDate} by truck ${deliveries.truckNumber} (Stop ${stopNumber})`
        : `Delivered on ${deliveryDate}`;
    } else {
      return deliveries.truckNumber != null && stopNumber != null
        ? `Delivery scheduled for ${moment(
            customerDetail.scheduledDeliveryDate
          ).format("MM/DD/YY")} on truck ${
            deliveries.truckNumber
          } (Stop ${stopNumber})`
        : `Delivery scheduled for ${moment(
            customerDetail.scheduledDeliveryDate
          ).format("MM/DD/YY")}`;
    }
  }

  function resolveMSNNumber(orderDetails) {
    if (
      orderDetails.deliveryDetail.poDetailList &&
      orderDetails.deliveryDetail.poDetailList.length
    ) {
      return orderDetails.deliveryDetail.poDetailList[0].msNbr;
    } else {
      return null;
    }
  }

  function getStatusHistoryClass() {
    return `status-history-section-${statusColor}`;
  }

  function getBlurClass() {
    const blurBackgroundDisplay = isRevisitDrawerOpen ? "blur" : "not-blur";
    return `display-box ${blurBackgroundDisplay}`;
  }

  function getColorFromEventList() {
    if (orderEvents) {
      if (
        orderEvents.some(
          (orderEvent) => orderEvent.orderEventDescription === "Cancelled"
        )
      ) {
        return "gray";
      }
      const latestEvent = getLatestEvent();
      if (latestEvent) {
        switch (latestEvent.orderEventDescription) {
          case constants.ORDER_PROCESSED:
          case constants.PO_RECEIVED_EVENT:
            return "blue";
          case constants.RESCHEDULED:
            return "blue";
          case constants.ON_THE_WAY:
          case constants.AT_LOCATION:
          case constants.DELIVERED:
            return "green";
          case constants.DELIVERED_W_EXCEPTION:
          case constants.MISSED_W_ATTEMPT:
            return "red";
          case constants.MISSED_WO_ATTEMPT:
            return "red";
          case constants.CANCELLED:
            return "gray";
          default:
            return;
        }
      }
    } else if (orderEvents === null) {
      return "red";
    }
  }

  function getLatestEvent() {
    return orderEvents
      ? orderEvents.sort(sortTimestamps)[orderEvents.length - 1]
      : null;
  }

  function sortTimestamps(t1, t2) {
    return t1.orderEventCrtTimeStamp - t2.orderEventCrtTimeStamp;
  }

  function isLegacyRevisit() {
    return (
      constants.REVISIT_SERVICE_CODES_MAP.has(orderDetails.levelOfService) &&
      orderDetails.sourceSystem === constants.SOURCE_SYSTEM_ADMS
    );
  }

  function isComRevisit() {
    return (
      constants.REVISIT_SERVICE_CODES_MAP.has(orderDetails.levelOfService) &&
      orderDetails.sourceSystem &&
      orderDetails.sourceSystem
        .trim()
        .toUpperCase()
        .includes(constants.SOURCE_SYSTEM_ORDER_UP)
    );
  }

  function isRevisit() {
    return isLegacyRevisit() || isComRevisit();
  }

  function getPurchaseOrderLineItems() {
    return (poDetailList || [])
      .flatMap((orderDetail) => orderDetail.lineLevelDetailList || [])
      .map((lineItem) => ({
        orderLineReference: lineItem.lineNbr,
        quantityToCancel: parseInt(lineItem.quantity),
        skuCode: lineItem.skuNbr,
        // TODO if needed, we can send modelNbr as the itemDesc is always null
        skuCodeDesc: lineItem.itemDesc,
      }));
  }

  function getWorkOrderLineItems() {
    return woDetail
      ? (woDetail.lineLevelDetailList || []).map((lineItem) => ({
          orderLineReference: lineItem.lineNbr,
          quantityToCancel: parseInt(lineItem.quantity),
          skuCode: lineItem.skuNbr,
          // TODO if needed, we can send modelNbr as the itemDesc is always null
          skuCodeDesc: lineItem.itemDesc,
        }))
      : [];
  }

  function getCancelledLines() {
    return [].concat(getPurchaseOrderLineItems(), getWorkOrderLineItems());
  }
  return (
    <>
      {showDeliveryDate && (
        <div className="result-status">{statusString(orderDetails)}</div>
      )}
      <div
        className="delivery-details"
        data-testid="delivery-details-container"
      >
        <OrderContext.Provider
          value={{
            setRevisitDrawerOpen,
            isRevisitDrawerOpen,
            updateOrder,
            toggleManualOrder,
            isCustomerInfoUpdated,
            revisitType,
            setRevisitType,
            setCustomerInfoIsUpdated,
            isUpdateCustomerInfoLoading,
            setUpdateCustomerInfoIsLoading,
            isCustomerInfoOpen,
            openCustomerInfoModal,
          }}
        >
          <div className={getStatusHistoryClass()}>
            <div className={getBlurClass()}>
              <Row>
                <Col
                  xl={{ span: 4 }}
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <DeliveryWindow
                    deliveryWindowDetail={orderDetails.deliveryWindowDetail}
                    scheduledDeliveryDate={
                      orderDetails.customerDetail &&
                      orderDetails.customerDetail.scheduledDeliveryDate
                    }
                    latestEvent={getLatestEvent()}
                  />
                </Col>
                <Col
                  xl={{ span: 4 }}
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <CustomerInfo
                    showPen={true}
                    customerDetail={orderDetails.customerDetail}
                    workOrderNumber={
                      orderDetails.deliveryDetail &&
                      orderDetails.deliveryDetail.woDetail &&
                      orderDetails.deliveryDetail.woDetail.woNbr
                    }
                    orderNumber={
                      orderDetails.customerDetail &&
                      orderDetails.customerDetail.custOrdId
                    }
                    orderIndex={orderIndex}
                    purchaseOrderDetailList={
                      orderDetails.deliveryDetail &&
                      orderDetails.deliveryDetail.poDetailList
                    }
                    location={location}
                    orderSource={orderDetails.sourceSystem}
                    onlineCommonsFlag={orderDetails.onlineCommonsFlag}
                    storeNumber={orderDetails.storeNbr}
                  />
                </Col>
                <Col
                  xl={{ span: 4 }}
                  lg={{ span: 6 }}
                  md={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <div className="order-info-container">
                    {isRevisit() && (
                      <div id="revisit-pill">
                        <span id="revisit-static-text">REVISIT</span>
                        <span id="revisit-type-text">
                          | {orderDetails.manuallyAddedReasonText}
                        </span>
                      </div>
                    )}
                    {
                      <div className="order-module">
                        {orderDetails.deliveryDetail && (
                          <WorkOrderDetail
                            workOrderDetail={
                              orderDetails.deliveryDetail.woDetail
                            }
                            deliveryAgentDetail={
                              orderDetails.deliveryDetail.deliveryAgent
                            }
                          />
                        )}
                        <PurchaseOrders
                          isComRevisit={isComRevisit()}
                          deliveryDetail={orderDetails.deliveryDetail}
                          isLegacyRevisit={isLegacyRevisit()}
                        />
                      </div>
                    }
                  </div>
                </Col>
                <Col
                  xl={{ span: 8 }}
                  lg={{ span: 12 }}
                  md={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <div className="notes-container">
                    <Notes
                      deliveryNoteStatus={orderDetails.deliveryNoteStatus}
                      notes={orderDetails.deliveryNoteList}
                      customerName={{
                        firstName:
                          orderDetails.customerDetail &&
                          orderDetails.customerDetail.custFirstName,
                        lastName:
                          orderDetails.customerDetail &&
                          orderDetails.customerDetail.custLastName,
                      }}
                      deliveryDate={
                        orderDetails.customerDetail &&
                        orderDetails.customerDetail.scheduledDeliveryDate
                      }
                      referenceId={orderDetails.referenceId}
                      orderNumber={orderDetails.customerDetail.custOrdId}
                      sortBy={"createTimeStamp"}
                      msNbr={resolveMSNNumber(orderDetails)}
                      view={constants.DASHBOARD_CONTEXT}
                    />
                  </div>
                </Col>
                <Col
                  xl={{ span: 4 }}
                  lg={{ span: 6 }}
                  md={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <Actions
                    actionTask={orderDetails.actionTask}
                    referenceId={orderDetails.referenceId}
                    customerDetail={orderDetails.customerDetail}
                    originalMSN={orderDetails.originalMSN}
                    deliveryDetailAgent={orderDetails.deliveryDetail}
                    workOrderDetail={
                      orderDetails.deliveryDetail &&
                      orderDetails.deliveryDetail.woDetail
                    }
                    purchaseOrderDetailList={
                      orderDetails.deliveryDetail &&
                      orderDetails.deliveryDetail.poDetailList
                    }
                    location={location}
                    orderSource={orderDetails.sourceSystem}
                    manualOrderable={
                      orderDetails.deliveryDetail.manualOrderable
                    }
                    notes={orderDetails.deliveryNoteList}
                    isComRevisit={isComRevisit()}
                    isLegacyRevisit={isLegacyRevisit()}
                    revisitLevelOfService={orderDetails.levelOfService}
                    latestEvent={getLatestEvent()}
                    cancelledLines={getCancelledLines()}
                    orderIndex={orderIndex}
                    orderNumber={
                      orderDetails.customerDetail &&
                      orderDetails.customerDetail.custOrdId
                    }
                  />
                </Col>
              </Row>
            </div>
          </div>
          {shouldLoadRevisitDrawerInDashboard(location, revisitType) ? (
            <RevisitDrawer
              customerOrderNumber={updatedCustomerDetail.customerOrderNumber}
              workOrderNumber={
                woDetail && woDetail.woNbr ? woDetail.woNbr : null
              }
              customerDetail={updatedCustomerDetail}
              purchaseOrderDetail={updatedPurchaseOrderDetails}
              location={location}
              orderCardColor={getColorFromEventList()}
              isDrawerOpen={isRevisitDrawerOpen}
              setDrawerOpen={setRevisitDrawerOpen}
              orderIndex={orderIndex}
            />
          ) : (
            openManualOrder && (
              <div className="manual-order-wrapper" data-testid="manual-order">
                <div
                  className={`${getStatusHistoryClass()} status-history-section-mo`}
                >
                  <div className="display-box-mo">
                    <ManualOrder
                      {...props}
                      getStatusHistory={getStatusHistoryClass()}
                      revisitType={revisitType}
                      orderIndex={orderIndex}
                    />
                  </div>
                </div>
              </div>
            )
          )}
        </OrderContext.Provider>
      </div>
    </>
  );
};

DeliveryDetails.propTypes = { orderDetails: PropTypes.object.isRequired };

export default DeliveryDetails;
