import { userRole } from "./Roles";

const permissions = {
  dashboard: {
    EMT_Corp: true,
    EMT_N: true,
    ADMS_MDO_USERS: true,
    "ADMS Delivery Agents": true,
    "DMP Call Center": true,
    "ADMS Vendors": true,
    "ADMS Logistics Users": true,
    "ADMS Logistics Managers": true,
    Default: true,
  },

  locationDropdown: {
    EMT_Corp: true,
    EMT_N: true,
    EMT_FDC: true,
    ADMS_MDO_USERS: true,
    "ADMS Delivery Agents": true,
  },

  EMTaccess: {
    EMT_Corp: true,
    EMT_N: true,
    EMT_FDC: true,
    ADMS_MDO_USERS: true,
    "ADMS Logistics Users": true,
    "ADMS Logistics Managers": true,
  },

  MARSaccess: {
    EMT_Corp: true,
    EMT_N: true,
    ADMS_MDO_USERS: true,
    "ADMS Vendors": true,
    "ADMS Logistics Users": true,
    "ADMS Logistics Managers": true,
  },

  addNote: {
    EMT_Corp: true,
    EMT_N: true,
    EMT_FDC: true,
    ADMS_MDO_USERS: true,
    "ADMS Delivery Agents": true,
    "DMP Call Center": true,
    "ADMS Vendors": true,
  },

  locationBasedSearch: {
    EMT_Corp: true,
    EMT_N: true,
    ADMS_MDO_USERS: true,
    "ADMS Delivery Agents": true,
  },

  reschedule: {
    EMT_Corp: true,
    EMT_N: true,
    EMT_FDC: true,
    ADMS_MDO_USERS: true,
    "ADMS Delivery Agents": true,
    "ADMS Vendors": true,
    "DMP Call Center": true,
    "ADMS Logistics Managers": true,
  },

  applyRescheduleNoOverrideFilter: {
    "ADMS Vendors": true,
    "DMP Call Center": true,
    "ADMS Logistics Users": true,
    "ADMS Logistics Managers": true,
  },

  applyMONoOverrideFilter: {
    "ADMS Vendors": true,
    "DMP Call Center": true,
    "ADMS Logistics Users": true,
    "ADMS Logistics Managers": true,
  },

  applyRescheduleProductFilter: {
    "ADMS Vendors": true,
  },

  isCustContactModeShownAndMandatory: {
    "ADMS Vendors": true,
    "DMP Call Center": true,
  },

  updateCustInfo: {
    EMT_Corp: true,
    EMT_N: true,
    EMT_FDC: true,
    ADMS_MDO_USERS: true,
    "ADMS Delivery Agents": true,
    "ADMS Vendors": true,
    "DMP Call Center": true,
    "ADMS Logistics Managers": true,
  },

  applyUpdateCustInfoProductFilter: {
    "ADMS Vendors": true,
  },

  viewPod: {
    EMT_Corp: true,
    EMT_N: true,
    ADMS_MDO_USERS: true,
    "ADMS Delivery Agents": true,
    "DMP Call Center": true,
    "ADMS Vendors": true,
    "ADMS Logistics Users": true,
    "ADMS Logistics Managers": true,
  },

  cancelMO: {
    EMT_Corp: true,
    EMT_N: true,
    ADMS_MDO_USERS: true,
    "ADMS Delivery Agents": true,
    "ADMS Vendors": true,
    "DMP Call Center": true,
  },

  mailSandboxAccess: {
    "ADMS Vendors": true,
  },

  showLocationDropdownInDateFilter: {
    "ADMS Vendors": true,
    "ADMS Logistics Managers": true,
    "ADMS Logistics Users": true,
    "DMP Call Center": true,
  },

  canCreateManualOrder: {
    EMT_Corp: true,
    EMT_N: true,
    ADMS_MDO_USERS: true,
    "ADMS Delivery Agents": true,
    "DMP Call Center": true,
    "ADMS Vendors": true,
  },

  canCreateNewManualOrder: {
    EMT_Corp: true,
    EMT_N: true,
    ADMS_MDO_USERS: true,
    "ADMS Vendors": true,
    "ADMS Delivery Agents": true,
  },

  dataMaintenanceAccess: {
    EMT_Corp: true,
    EMT_N: true,
    ADMS_MDO_USERS: true,
    "ADMS Delivery Agents": true,
    "ADMS Vendors": true,
    "ADMS Logistics Managers": true,
    "ADMS Logistics Users": true,
  },

  showDAVendorName: {
    EMT_Corp: true,
    EMT_N: true,
    ADMS_MDO_USERS: true,
    "ADMS Delivery Agents": true,
    "DMP Call Center": true,
  },

  workbinAccess: {
    EMT_Corp: true,
    EMT_N: true,
    EMT_FDC: true,
  },

  dashboardCommonAccess: {
    EMT_Corp: true,
    EMT_N: true,
    EMT_FDC: true,
    ADMS_MDO_USERS: true,
    "ADMS Delivery Agents": true,
    "DMP Call Center": true,
  },

  dashboardSthAccess: {
    EMT_Corp: true,
    EMT_N: true,
    ADMS_MDO_USERS: true,
    "ADMS Delivery Agents": true,
  },

  allWorkOrdersButton: {
    EMT_Corp: true,
    EMT_N: true,
    EMT_FDC: true,
    ADMS_MDO_USERS: true,
    "DMP Call Center": true,
    "ADMS Logistics Managers": true,
    "ADMS Logistics Users": true,
  },

  cancelDelivery: {
    EMT_Corp: true,
    ADMS_MDO_USERS: true,
    "DMP Call Center": true,
  },

  routePlanner: {
    EMT_Corp: true,
    EMT_N: true,
    ADMS_MDO_USERS: true,
    ADMS_Delivery_Agents: true,
  },
  trueOverage: {
    EMT_Corp: true,
    EMT_N: true,
    ADMS_MDO_USERS: true,
    "ADMS Delivery Agents": true,
  },
};

export default (feature, user) => {
  const role = userRole(user);
  return permissions[feature][role] !== undefined;
};

export const checkAdditionalRoleAccessForUser = (feature, user) => {
  return !!(user.roles && user.roles.includes(feature));
};
