import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import getDrdsBffUrl from "../../util/RoutePlanner/getDrdsBffUrl";
import { API_CONFIG } from "../../util/RoutePlanner/routePlannerUtils";
import LocationContext from "../../context/LocationContext";
import { identifyTab, checkPageType } from "./useContextManager";
import moment from "moment";

const useRoutePlannerManifestData = (isCsvFlow = false) => {
  const routerLocation = useLocation();
  const locationContext = useContext(LocationContext);
  const { currentLocation } = useContext(LocationContext);

  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setData([]);
  }, []);

  useEffect(() => {
    if (checkPageType(routerLocation) === "Manifest" || isCsvFlow) {
      const queryParams = queryStringDecoder(routerLocation);
      const tab = identifyTab(routerLocation);
      if (tab === "dispatch") {
        queryParams.date = moment().format("YYYY-MM-DD");
      }
      const apiQuery = apiQueryBuilder(tab, queryParams, currentLocation);
      fetch(apiQuery);
    }
  }, []);

  async function fetch(apiQuery) {
    setLoading(true);
    try {
      const drdsApiResponse = await axios.get(
        `${getDrdsBffUrl()}${API_CONFIG.BASE_URL}${
          API_CONFIG.ENDPOINTS.MANIFEST_URL
        }${apiQuery}`
      );
      setData(drdsApiResponse.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(error.message);
      setData([]);
    } finally {
      setLoading(false);
    }
  }

  return {
    data,
    error,
    loading,
  };
};

export function apiQueryBuilder(tab, queryParams, currentLocation) {
  return `?tab=${tab}&location=${currentLocation.locationNumber}&date=${
    queryParams.date
  }${queryParams.routeId ? `&routeId=${queryParams.routeId}` : ""}`;
}

export function queryStringDecoder(routerLocation) {
  const queryDict = {};
  const pathArray = routerLocation.pathname
    ? routerLocation.pathname.split("/")
    : [];

  if (pathArray.length) {
    const routeId = pathArray.find((element) => /\d+/.test(element));
    if (routeId) {
      queryDict.routeId = routeId;
    }
  }

  if (routerLocation.search) {
    [, queryDict.date] = routerLocation.search.split("=");
  }

  return queryDict;
}

export default useRoutePlannerManifestData;
